.budget {
    justify-content: space-between;
    align-items: center;
    padding: 200px 30px;
    padding-bottom: 100px;
    text-align: center;
    opacity: 0; 
    transition: opacity 0.8s ease-in-out;
}

.budget.in-view {
    opacity: 1;
  }

.budget-content p {
    color: #181818;
    margin: 0;
    font-size: 25px;
    line-height: 1.6;
    padding: 0 px;
    text-align: center;
}

.aappContact {
    max-width: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0.8rem;
    background-color: #46c855;
    color: white;
    margin: 15px auto;
    white-space: nowrap;
    text-decoration: none;
    border-radius: 500px;
    transition: background-color 0.3s, color 0.3s ease-in-out;
}
  
.aappContact i {
    font-size: 2rem;
    margin-right: 0.5rem;
}

.aappContact:hover {
    background-color: rgba(70, 200, 85, 0.67);
    color: black;
}



@media screen and (max-width: 768px) {

    .aappContact {
        max-width: 80%;

    }
}




