.body {
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    font-family: 'Roboto', sans-serif;
    color: #000000;
    display: flex;
}

.container {
    align-items: center;
    width: 80%;
    margin: 0 auto;
    padding: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.500);
}

.title {
    color: #000000d3;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 50px;
    text-align: center;
    margin-bottom: 0px;
    padding: 0;
}

.subtitle {
    color: #181818;
    font-size: 25px;
    line-height: 1.6;
    padding: 0 10%;
    text-align: center;
}

.subtitle::before {
    content: "";
    display: block;
    width: 100px;
    height: 4px;
    background-color: rgb(0, 54, 127);
    margin: 0 auto 35px;
}

.text {
    flex: 1;
    text-align: center;
}

.sub-text {
    color: #000000d3;
    text-align: center;
    font-size: 35px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}



@media screen and (max-width: 768px) {
    .container {
        width: 100%;
    }

    .Home,
    .About,
    .OurServices,
    .Budget,
    .Assessments,
    .Footer {
        padding: 0;
    }
    
    .text {
        text-align: center; 
        margin-bottom: 20px; 
    }
           
    .title {
        font-size: 9vw;
        padding: 0;
    }
        
    .subtitle {
        font-size: 18px;
        margin-bottom: 50px;
    }


    
}