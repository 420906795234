#header {
    position: sticky;
    top: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    background: rgb(0, 54, 127);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.500);
    z-index: 1000;      
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper ul {
    display: flex;
    align-items: center;    
}

.wrapper li {
    display: inline-flex;
}

.wrapper {
    margin: 0 auto;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    
    
    transition: transform 0.5s ease; /* Match with your state change duration */

}

.nav-logo img {
    margin: 0;
    padding: 0;
    height: 8vw;
    -webkit-filter: drop-shadow(0px 0px 10px #000000);
    filter: drop-shadow(0px 0px 10px #000000);
}

.nav-link {
    margin: 0 0.6em;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
    color: #d6d6d6;
    font-size: 1.8rem;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
    
}

.nav-link:hover {
    color: rgb(0, 0, 0);
    text-decoration: none;
    text-shadow: 0px 0px 2px rgb(0, 0, 0);
}

input {
    display: none;
}

.appContact {
    position: fixed;
    padding: 0.5rem 0.8rem;
    bottom: 20px;
    right: 10%;
    background-color: #46c855;
    color: white;
    margin: 0 1rem;
    white-space: nowrap;
    display: flex;
    text-decoration: none;
    align-items: center;
    border-radius: 500px;
    transition: background-color 0.3s, color 0.3s ease-in-out;
    z-index: 1000;

}

.appContact i {
    font-size: 2rem;
    display: flex;
    margin-right: 0.5rem;
}

.appContact:hover {
    background-color: rgba(70, 200, 85, 0.67);
    color: black;
}

.navbar .menu-btn,
.navbar .close {
    display: none;
}


@media screen and (max-width: 768px) {
    .header {
        width: 100%;
    }

    .nav-logo img {
        height: 20vw;
        -webkit-filter: drop-shadow(0px 0px 10px #000000);
        filter: drop-shadow(0px 0px 10px #000000);
    }

    .navbar .menu-btn,
    .navbar .close {
        display: flex;
        z-index: 1000;
    }
    
    .nav-link {
        margin: 0 0.6em;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
        font-size: 1rem;
    }


    
    .wrapper {
        position: fixed;
        top: -130%;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 11, 25, 0.976);
        transition: all 0.6s ease-in-out;
        z-index: 999;
    }

    .wrapper ul {
        position: absolute;
        flex-direction: column;
        text-align: center;
        top: 40%;
        left: 40%;
        transform: translate(-50%, -50%);
        text-align: center;
      }

      .wrapper li {
        margin: 15px 0;
      }

    #active:checked~.wrapper {
        top: 0;
    }

    .menu-btn {
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 10px;
        height: 70px; 
        width: 70px;
        cursor: pointer;
        text-align: center;
        line-height: 50px;
        border-radius: 50%;
        font-size: 20px;
        display: block;
        transition: all 0.3s ease-in-out; 
    }

    .menu-btn span,
    .menu-btn:before,
    .menu-btn:after {
        content: "";
        position: absolute;
        top: calc(50% - 1px);
        left: 30%;
        width: 40%;
        border-bottom: 3px solid #ffffff;
        transition: transform .6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .menu-btn:before {
        transform: translateY(-8px);
    }

    .menu-btn:after {
        transform: translateY(8px);
    }

    .close {
        z-index: 1;
        width: 100%;
        height: 100%;
        pointer-events: none;
        transition: background .6s;
    }

    #active:checked+.menu-btn:before {
        transform: rotate(45deg);
        border-color: #fff;
    }

    #active:checked+.menu-btn:after {
        transform: rotate(-45deg);
        border-color: #fff;
    }





 .appContact {
    display: none;
}


} 
