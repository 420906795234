.about {
    padding: 200px 0;
    opacity: 0; 
    transition: opacity 0.8s ease-in-out;
}

.about.in-view {
    opacity: 1;
}

.about-container {
    max-width: 1200px;
    margin: 0 auto;
}

.about-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
}

.about-image {
    max-width: 100%;
    height: auto;
    border-radius: 5%;
    box-shadow: 0 0 15px rgb(0, 0, 0);
}

@media screen and (max-width: 768px) {

    .about {
        padding: 20px 0;
    }

    .about-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .about-image {
        width: 80%;
    }
}