
.mvv-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; 
  margin-bottom: 20px; 
}

.image-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  margin: 50px;
  border-radius: 5%;
  box-shadow: 0 0 15px #00000080;
  width: 100%; 
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mission-vision-values {
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 20px;

  border: '1px solid red';
}


.text-bottom {
  margin: 0 80px 100px 80px;    
  color: #181818;
  font-size: 25px;
  line-height: 1.5;
  padding: 0 10%;
  text-align: center;
}


ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 20px;
  color: #181818;
  font-size: 20px;
  padding: 0 px;
  text-align: left;
}

.mission-text {
  flex: 1;
  text-align: center;
}
.vision-text {
  flex: 1;
  text-align: center;
}
.values-text {
  margin: 30px;
  flex: 1;
  text-align: center;
}

.values-text li {
  color: #000000d3;
  margin-bottom: 10px; 
}

.sub {
  color: #000000d3;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 50px;
  text-align: center;
  margin-bottom: 0px;
  padding: 0;
}

.subtitle2 {
  color: #181818;
  font-size: 25px;
  line-height: 1.6;
  padding: 0 10%;
  text-align: center;
}

.subtitle2::before {
  content: "";
  display: block;
  width: 100px;
  height: 4px;
  background-color: rgb(0, 54, 127);
  margin: 0 auto 35px;
}

@media screen and (max-width: 768px) {
  .image-container {
    flex: 1;
    display: none;
  }
    
  .mission-text {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../assets/img/missionVisionValues/mission.jpg');
    background-position: center;
    background-size: cover;
    border-radius: 20px;
  }

  .vision-text {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../assets/img/missionVisionValues/vision.jpg');
    background-position: center;
    background-size: cover;
    border-radius: 20px;
  }

  .values-text {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../assets/img/missionVisionValues/values.jpg');
    background-position: center;
    background-size: cover;
    border-radius: 20px;
  }

  .sub {
    font-size: 9vw;
    padding: 0;
    color: #ffffffd3;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .subtitle2 {
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 50px;
  }

  .values-text li {
    color: #ffffffd3;
    line-height: 1.6;
    padding: 0 20px 15px;
    margin-bottom: 10px; 
    background-color: rgba(0, 0, 0, 0.656);
  }

  .text-bottom {
    margin: 0 0 50px ;    
    color: #181818;
    font-size: 17px;
    line-height: 1.5;
    padding: 0 10%;
    text-align: center;
  }
}