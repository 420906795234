.our-services-container {
    background-image: linear-gradient(rgba(0, 54, 127, 0.92), rgba(0, 54, 127, 0.92)), url('../../assets/img/backgroung_footer2.jpg');
    background-size: cover;
    padding: 20px;
    position: relative;
    opacity: 0; 
    transition: opacity 0.8s ease-in-out;
}

.our-services-container.in-view {
    opacity: 1;
}

.our-services-container::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-color: #00367f  transparent transparent;
}

.our-services-container::after {
    bottom: -50px; 
    border-width: 50px 39vw 0;
}

.our-services-container h2 {
    color: #fff;
    text-align: center;
    font-size: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.743);
}

.our-services-container .slick-dots {
    opacity: 0;
}

.slick-slide{
    margin-top: 20px;
}
.our-services-slider {
    margin-top: 50px;
}
  

.our-services-slider .slick-slide .service-item img {
    width: 50%;
    border-radius: 50%;
    margin-bottom: 10px;;
  }
  

.our-services-slider .slick-slide .service-item img {
    -webkit-filter: none; 
    filter: none; 
    box-shadow: 0 0 25px rgb(0, 0, 0);
    margin: 0 auto 10px;
    display: block;
}

  .service-item p{
    text-align: center;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.9);
    text-shadow: 1px 1px 5px rgb(0, 0, 0);
}
  
.our-services-slider .slick-prev,
.our-services-slider .slick-next {
    width: 50px;
    height: 100%;
    line-height: 50px;
    text-align: center;
    transition: all 0.3s ease;
    transform-origin: center center;
    z-index: 1;
    opacity: 0;
    border-radius: 0;
}

.our-services-slider .slick-next:before,
.our-services-slider .slick-prev:before {
    color: rgb(255, 255, 255);
    opacity: 1;
    text-shadow: 0px 0px 10px rgb(255, 255, 255);
}

.our-services-slider .slick-next:hover,
.our-services-slider .slick-prev:hover {
    opacity: 1;
}

.sub-services {
    color: #ffffffd3;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 50px;
    text-align: center;
    margin-bottom: 0px;
    padding: 0;
  }








@media (max-width: 768px) {
    .our-services-container::after {
        bottom: -12%; 
        border-width: 50px 80vw 0;
    }

    .sub-services {
        color: #ffffffd3;
        font-size: 30px;
    }
    
    .service-item p{
        text-align: center;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.9);
        text-shadow: 1px 1px 5px rgb(0, 0, 0);
        
    }
        
    .our-services-slider .slick-slide .service-item img {
        width: 70%;
    }

    .our-services-slider p{
        color: #ffffffd3;
        font-size: 15px;

    }
    
}