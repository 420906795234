.assessments {
    justify-content: space-between;
    align-items: center;
    padding-top: 200px;
    text-align: center;
    padding-bottom: 100px;
}

.text-assessments {
    margin: 20px 80px 100px 80px;    
    color: #df0909;
    font-size: 25px;
    line-height: 1.5;
    padding: 0 10%;
    text-align: center;
  }


@media screen and (max-width: 768px) {
    .assessments {
        padding-top: 80px;
    }
    .text-assessments {
        margin: 20px 5px;       
        color: #181818;
        font-size: 17px;
        line-height: 1.5;
        padding: 0 10%;
        text-align: center;
      }
}